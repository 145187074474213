@import '../scss/variables';
@import '~@coreui/coreui-pro/scss/bootstrap/mixins/breakpoints';

.uploader {
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  max-width: 202px;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  padding-bottom: 0.5rem;
  @include media-breakpoint-down(sm) {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;
    max-width: 100%;
  }

  * {
    outline: none;
  }

  &__bg {
    background-color: #f9f9f9;
  }

  &__remove-btn {
    @include media-breakpoint-down(sm) {
      margin-left: 5px;
    }

    &--hide {
      opacity: 0;
      pointer-events: none;
    }
  }

  &__title-wrapper {
    @include media-breakpoint-down(sm) {
      margin-left: 1rem;
    }
    @include media-breakpoint-up(sm) {
      margin-bottom: 1rem;
    }
  }

  &__title {
    color: $blue-80;
    white-space: pre-wrap;
    margin-right: 1rem;

    button, span {
      display: inline;
    }

    @include media-breakpoint-down(sm) {
      margin-right: 0;
      max-width: 100px;
      width: auto;
    }

    &__supported, br {
      display: none;
      @include media-breakpoint-down(sm) {
        display: inline-block;
      }
    }
  }

  &__error {
    padding-top: 5px;
    color: $error;
    white-space: pre-wrap;
  }


  &__drag-box {
    width: 202px;
    height: 162px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: dashed 1px $gray-30;
    transition: border-color 0.3s, background-color 0.5s;
    cursor: pointer;

    @include media-breakpoint-down(sm) {
      max-width: 100px;
      max-height: 56px;
    }

    &--disabled {
      cursor: progress;
    }

    &--success {
      border-color: transparent;
      background-color: $gray-10;
    }

    &--error {
      border: 1px dashed $error;
    }

    &--success &--active, &--active {
      border: 1px solid $green-10;
    }

    &__add-document {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      width: 100%;

      &__uploaded-image {
        max-height: 100%;
        max-width: 100%;
        width: auto;
        border-radius: 4px;
        align-self: normal;
        margin: auto;
      }


      &__img {
        &--mobile {
          width: 20px;
          height: 20px;
          margin: 0 2px;
          background-color: $gray-50;
          text-align: center;
          color: $white;
          border: 1px solid $gray-50;
          border-radius: 12px;

          span {
            position: relative;
            bottom: 1px;
          }

          @include media-breakpoint-up(md) {
            display: none;
          }
        }

        &--desktop {
          @include media-breakpoint-down(sm) {
            display: none;
          }
        }
      }

      &__supported {
        padding-top: 15px;
        color: $gray-30;
      }

      &__browse {
        padding-top: 8px;
        color: $dark-blue;
      }

      @include media-breakpoint-down(md) {
        &__browse, &__supported {
          padding-top: 5px;
        }
      }
      @include media-breakpoint-down(sm) {
        &__browse, &__supported {
          display: none;
        }
        img {
          max-width: 100%;
          max-height: 100%;
          width: auto;
        }
      }
    }
  }
}
