@import '../scss/variables';

.securitize-toast{
  display: flex;
  align-items: center;
  color:$white;
  padding: 0 16px;
  &__icon{
    color:$white;
    text-align: center;
    font-size:24px;
    width:24px;
    height:24px;
  }
  &__body{
    padding: 0 16px;
    &__content{
      min-height:22px;
      line-height:22px;
      font-size:16px;
    }
  }
}
