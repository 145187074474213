@import '../scss/variables';
@import '~@coreui/coreui-pro/scss/bootstrap/mixins/breakpoints';

.anonymous-docs {
  display: inline-flex;
  flex-direction: column;
  max-width: 202px;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  padding-bottom: 0.5rem;
  @include media-breakpoint-down(sm) {
    max-height: 56px;
    flex-direction: row-reverse;
    max-width: 100%;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }


  &__title > &__verified-status {
    @include media-breakpoint-down(md) {
      margin-top: 1rem;
      display: block;
    }
    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  &__title {
    margin-bottom: 12px;
    color: $blue-80;
    @include media-breakpoint-down(sm) {
      margin-left: 1rem;
    }
  }

  &__docs-display {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 202px;
    height: 162px;
    border-radius: 6px;

    img {
      max-width: 100%;
      max-height: 100%;
      width: auto;
    }

    @include media-breakpoint-down(xs) {
      width: 100%;
    }
    @include media-breakpoint-down(sm) {
      max-width: 100px;
      height: 90px;
      max-height: 50px;
    }

    &--success {
      background-color: $blue-25;
    }

    &--failed {
      background-color: $red-25;
    }

    &--pending {
      background-color: $yellow-25;
    }
  }

  &__verified-status {
    @include media-breakpoint-down(sm) {
      display: none;
    }

    i {
      margin-right: 8px;
    }

    &--success, &--success i {
      color: $green-10;
    }

    &--failed, &--failed i {
      color: $rose-red;
    }

    &--pending, &--pending i {
      color: $yellow-40;
    }
  }
}
