@import '../scss/variables';
@import '~@coreui/coreui-pro/scss/bootstrap/mixins/breakpoints';

.server-error-page {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;

  &__content {
    @include media-breakpoint-between(sm, xl) {
      width: 320px;
    }

    .ladda-button[data-style=zoom-out] {
      .ladda-spinner {
        margin-left: 0 ;
      }
    }
  }
}
