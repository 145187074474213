@import '~@securitize/ui-components/scss/variables';
@import "~@coreui/coreui-pro/scss/bootstrap/mixins/breakpoints";
@import '../../scss/variables';

.uploader-loader {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    color: $blue-50;
  }

  &__progress {
    @include media-breakpoint-down(xs) {
      margin-top: 2px;
    }
    margin-top: 9px;
    width: 80px;
    height: 8px;
    border-radius: 13px;
    background-color: $blue-10;

    &__bar {
      background-color: $dark-blue;
    }
  }
}
