@import '../scss/variables';

.tooltip-info {
  .arrow {
    display: none;
  }

  .tooltip-inner {
    background: $white;
    padding: 16px;
    color: $blue-80;
    font-size: 12px;
    font-family: $Roboto;
    backface-visibility: hidden;
    transform: scale(1.02) translateZ(0);
  }

  .tooltip {
    padding: 0;
    margin: 0.5rem;
    box-shadow: $shadow-light;

    &.show {
      opacity: 1;
    }
  }
}
