@import '../scss/variables';

.language-select {
  margin: 2% 1%;
  position: absolute;
  right: 0;
  .dropdown-toggle.btn.btn-secondary,
  .dropdown-toggle.btn.btn-secondary:focus {
    background: none;
    border: none;
    box-shadow: none;
  }

  .dropdown-toggle.btn.btn-secondary:focus{
    background: $gray-20;
  }
}
